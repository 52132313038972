import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import img from 'img/blog/analyze-session-recordings.png';
import img1 from 'img/blog/filters.png';
import img2 from 'img/blog/engagementlistitem.png';
import img3 from 'img/blog/listitems.png';
import img4 from 'img/blog/sticky-notes.png';

const Content = () => {
  return (
    <Post>
      <p>You’ve got hours of session recordings – now what?</p>
      <p>
        Do you really need to watch them all, minute by minute? Maybe there is some other way to
        analyze them and get insights faster?
      </p>
      <p>
        We know that you don’t have all the time in the world to watch session replay videos. If you
        were to watch them all, it would be a full-time job.
      </p>
      <p>
        Session replays are supposed to make your work easier and not add extra repetitive work. In
        this article, we’ll show you how to analyze session recordings the right way.
      </p>
      <p>We’re going to cover the following things:</p>
      <ul>
        <li>how to get started with analyzing session recordings</li>
        <li>what are the most common mistakes when interpreting session replays</li>
        <li>how the right session replay tool will help you analyze recordings more effectively</li>
        <li>what are our best tips for session recording analysis</li>
      </ul>
      <p>Ready to learn more, boost your productivity and improve conversions? Let’s dive in!</p>
      <ul>
        <li>
          <a href="#session-replay">How to get started with session replay analysis</a>
        </li>
        <li>
          <p>
            <a href="#analyzing-session">What to avoid when analyzing session replays</a>
          </p>
          <ul>
            <li>
              <a href="#confirmation-bias">Confirmation bias</a>
            </li>
            <li>
              <a href="#not-using-filters">Not using filters</a>
            </li>
            <li>
              <a href="#ignoring-available">Ignoring available integrations</a>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <a href="#tips-for-analyzing">Tips for analyzing session replays</a>
          </p>
          <ul>
            <li>
              <a href="#recording-filters">Session recording filters</a>
            </li>
            <li>
              <a href="#segmentation">Segmentation</a>
            </li>
            <li>
              <a href="#sticky-note">Sticky note strategy</a>
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="session-replay">How to get started with session replay analysis</h2>

      <p>
        Watching <strong>session recordings</strong> is one of the most effective conversion
        research methods. It’s commonly used by a wide range of companies, from SaaS businesses (see
        the{' '}
        <a href="/customers/rocketlink/" target="_blank" rel="noopener noreferrer">
          RocketLink case study
        </a>{' '}
        for more details) to ecommerce stores.
      </p>
      <p>
        If you’re looking for an inexpensive and convenient way to learn more about user behavior,
        session replays make a perfect choice. They can serve to test your hypotheses, as well as to
        formulate new ones.
      </p>
      <p>
        And here’s how to get started with this method:{' '}
        <strong>you need to set your goals first</strong>. Quantitative data from Google Analytics
        is a great place to start. For example, you can have a look at:
      </p>
      <ul>
        <li>the pages with highest bounce rates</li>
        <li>the most popular exit pages</li>
        <li>the conversion rate of your Google Analytics goals</li>
      </ul>
      <p>
        This information will help you formulate relevant hypotheses. Of course, the assumptions may
        be tweaked along the way – session recording can be a source of really surprising findings!
      </p>
      <h2 id="analyzing-session">What to avoid when analyzing session replays</h2>

      <p>
        You don’t always have to learn from your own mistakes. We’ve listed some of the most popular
        pitfalls to keep in mind:
      </p>
      <h3 id="confirmation-bias">Confirmation bias</h3>

      <p>
        Here’s one of the biggest issues with analyzing session replays: our interpretations will
        always remain subjective, at least to some extent.
      </p>
      <p>
        <strong>Confirmation bias</strong> is the danger lurking around the corner when you’re
        creating hypotheses for your research. The rule is quite simple: we focus on information
        that confirms our beliefs and we ignore data that goes against them. Most of the time, we’re
        not even aware that this is happening. For instance, when you hope that your new landing
        page is performing better than the previous one, you’re going to focus on behaviors that
        validate this assumption.
      </p>
      <p>
        Luckily, there are some tricks that will help you avoid the confirmation bias when analyzing
        your session recordings:
      </p>
      <ul>
        <li>
          <strong>Focus on the “why”</strong> – Analyzing session replays is a{' '}
          <a
            href="/blog/guide-to-qualitative-ux-research/"
            target="_blank"
            rel="noopener noreferrer "
          >
            qualitative research method
          </a>
          . When you watch a recording, always ask yourself why the user is behaving this way. What
          is their intent? Are they on the right way to achieve their goal?
        </li>
        <li>
          <strong>Come up with alternatives</strong> – Challenge your first assumptions. Even when
          something seems obvious, try a different interpretation and see how it fits. This simple
          practice can help you overcome your own brain’s limitations.
        </li>
        <li>
          <strong>Different points of view</strong> – If possible, share your findings with other
          team members. We’ve all got a thousand other tasks, but you don’t need to ask your
          workmates to go through the entire process. You can ask them to watch two or three
          recordings, without sharing your findings first, and see if their opinions will go in line
          with yours.
        </li>
      </ul>
      <aside>
        <b>Pro tip</b>: If this topic is interesting to you, we’re sure you’re going to enjoy our
        article about{' '}
        <a href="/blog/cognitive-bias-ux/" target="_blank" rel="noopener noreferrer">
          cognitive biases in research
        </a>
        .
      </aside>

      <h3 id="not-using-filters">Not using filters</h3>

      <p>
        If you’ve just only started your business, you might have enough time to watch all the
        sessions. That’s good, but it definitely won’t work in the long run. The number of your
        visitors will grow and you won’t be able to watch every single recording anymore.
      </p>
      <p>
        This is why it pays off to take a closer look at available filtering options and{' '}
        <strong>set up your first target segments</strong> at the very start. The more relevant data
        you’ll have, the better.
      </p>
      <h3 id="ignoring-available">Ignoring available integrations</h3>

      <p>
        Just like different types of software, session recording apps can be integrated with other
        tools for better performance. Need inspirations? Our favorite integration ideas include:
      </p>
      <ul>
        <li>
          Sending session recordings as events to{' '}
          <strong>
            <a
              href="/help/google-analytics-integration/"
              target="_blank"
              rel="noopener noreferrer "
            >
              Google Analytics
            </a>
          </strong>
        </li>
        <li>
          Adding links to session recordings in{' '}
          <strong>
            <a href="/help/intercom-integration/" target="_blank" rel="noopener noreferrer">
              Intercom conversations
            </a>
          </strong>
        </li>
        <li>
          Sending recordings to customer data platforms, such as{' '}
          <strong>
            <a href="https://segment.com/" target="_blank" rel="noopener noreferrer nofollow">
              Segment
            </a>
          </strong>
        </li>
      </ul>
      <p>
        With the right integration, all of this can happen automatically. Little tweaks like these
        sure to save a lot of time and make your work so much easier.
      </p>
      <h2 id="tips-for-analyzing">Tips for analyzing session replays</h2>

      <p>
        Now that you know what you should avoid, it’s time to answer another question: what you
        should do? Here are some of our best practices for analyzing session recordings:
      </p>
      <h3 id="recording-filters">Session recording filters</h3>

      <p>
        <img
          src={img1}
          alt="Illustration of session recordings."
          title="Session recording filters"
        />
      </p>
      <p>
        This is the key to analyzing your recordings effectively. You don’t have to take shots in
        the dark, watch all the recordings and hope to find the data you’re looking for.
      </p>
      <p>
        Instead, you can use different <strong>session recording filters</strong> to help you out.
        We’ll show you different possibilities on the example of LiveSession features:
      </p>
      <ul>
        <li>
          <p>
            <strong>Engagement score</strong> – This is the perfect feature to get you started. If
            you’d like to skip the recordings with little activity, you can do it with the{' '}
            <strong>
              <a href="/blog/engagement-score/" target="_blank" rel="noopener noreferrer">
                engagement score
              </a>
            </strong>
            :
          </p>
          <p>
            <img src={img2} alt="Engagement score in LiveSession" title="Engagement score" />
          </p>
          <p>
            So, how exactly does it work? It’s simple: every recording gets a score on a scale from
            1 to 5, where 5 stands for most activity, e.g. clicks, scrolls, long session duration
            and large number of pages visited. You can only choose to watch recordings with the
            highest score.
          </p>
        </li>
        <li>
          <p>
            <strong>Custom properties</strong> – Looking for sessions of users from a particular
            pricing option? You can find these very easily if you add{' '}
            <strong>
              <a
                href="/help/user-identification-custom-properties/"
                target="_blank"
                rel="noopener noreferrer"
              >
                custom properties
              </a>
            </strong>{' '}
            to your recordings. Apart from different pricing plans, you can also add the user’s ID
            from other analytics software. This will help you integrate data from different sources
            and get even more in-depth insights.
          </p>
        </li>
        <li>
          <strong>Inspect mode</strong> – What about recordings of a particular feature?{' '}
          <strong>
            <a href="/blog/inspect-mode/" target="_blank" rel="noopener noreferrer">
              Inspect mode
            </a>
          </strong>{' '}
          comes to the rescue here. This filter can be applied while you’re watching a session
          replay. You can choose an element in the recording (either a text value or a CSS selector)
          and immediately see all the sessions where someone has interacted with it.
        </li>
      </ul>
      <h3 id="segmentation">Segmentation</h3>

      <p>
        Apart from the features mentioned above, you can use a whole range of other, more granular
        filters:
      </p>
      <p>
        <img src={img3} alt="Segmentation filters" title="List of segmentation filters" />
      </p>
      <p>
        The best part? You don’t need to set up all dimensions manually every time you analyze your
        sessions. All the filters can be combined and saved to create{' '}
        <a
          href="/blog/how-to-use-filters-in-user-research/"
          target="_blank"
          rel="noopener noreferrer"
        >
          user segments
        </a>
        . This allows you to track how different segments are performing after you apply changes and
        test different conversion strategies on your website.
      </p>
      <h3 id="sticky-note">Sticky note strategy</h3>

      <p>
        <img src={img4} alt="Sticky notes on a wall." title="Sticky note strategy" />
      </p>
      <p>
        Last but not least, here’s our unplugged tip: when analyzing a particular hypothesis,{' '}
        <strong>keep a sticky note on your wall</strong>. Write down your goals and assumptions and
        treat them like your north star. Once you make the direction clear, you’ll be less
        vulnerable to distractions and biases. Good luck and happy analyzing!
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'How to Analyze Session Recordings (Effectively)',
  url: '/blog/analyze-session-recordings/',
  description: `Don’t know what to do with your session replays? Learn how to analyze session
  recordings, the right way.`,
  author,
  img,
  imgSocial: img,
  date: '2020-04-10',
  category: '',
  group: 'Analytics',
  timeToRead: 6,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
